import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./CarouselComponent.css";

const CarouselComponent = () => {
  return (
    <Carousel
      autoPlay={true}
      interval={7000}
      infiniteLoop={true}
      showStatus={false}
      showThumbs={false}
      className="carousel"
    >
      <div className="carousel-text">
        <p>
          ”Det viktigaste för oss var att vi skulle känna oss avslappnade och
          bekväma under fotograferingen och det lyckades verkligen Amanda med.
          Glad, snäll, rolig, proffsig och helt otrolig med barn. Exakt dem
          orden skulle vi beskriva Amanda med. Vi är så himla nöjda!”
        </p>
        <p>- Amanda & David</p>
      </div>
      <div className="carousel-text">
        <p>
          ”Kan varmt rekommendera Amanda som fotade mina flickor! Vi fick
          fantastiska bilder och flickorna var så trygga med Amanda”
        </p>
        <p>- Valbone</p>
      </div>
      <div className="carousel-text">
        <p>
          ”När jag först såg Amandas bilder förstod jag direkt att det var vår
          fotograf! Hon lyckas fånga känslorna och detaljerna som ingen annan.
          Varje bild är unik och personlig. Att maken glömde bort att vi var
          mitt i en fotografering talade om hur trygga och bekväma vi kände oss
          med Amanda ☺ Vi hade en väldigt rolig stund och är evigt tacksamma för
          de fina minnena och bilderna vi fick”
        </p>
        <p>- Nikoletta</p>
      </div>
      <div className="carousel-text">
        <p>
          ”Allt kändes så naturligt. Det var en mysig stund. Amanda är bra med
          barn och bilderna var fantastiska!”
        </p>
        <p>- Josefine</p>
      </div>
    </Carousel>
  );
};

export default CarouselComponent;
