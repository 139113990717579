import React from "react";
import "./Weddingpage.css";
import "./Layout.css";
import { useNavigate } from "react-router-dom";

const Weddingpage = () => {
  const nav = useNavigate();

  const navigate = () => {
    nav("/kontakt");
  };

  return (
    <div className="page-container">
      <img
        className="start-image"
        src={require("../images/Hemsida/Brollop/brollopsfoto1.jpg")}
        alt="Bröllopsfoto"
      ></img>
      <div className="text-container">
        <p className="text-header">BRÖLLOPSFOTOGRAFERING</p>
        <p className="text-item" style={{ fontStyle: "italic" }}>
          Äkta, vackert, naturligt och alldeles alldeles underbart!
        </p>
        <p className="text-item">
          Pirret i magen. Tåren i ögat. Leendet i mungipan. Kärleken i kyssen.
          Tryggheten i kramen. Ni är där för att uppleva det där och då. Jag är
          där för att bevara det åt er för alltid. Ögonblick förpackade i form
          av vackra fotografier som ni kan plocka fram när ni vill för att
          minnas den kanske största dagen i era liv. Det är en ära att få
          fotografera ett bröllop och ett uppdrag jag tar på största allvar. Som
          bröllopsfotograf får jag tillbringa mycket tid med brudparet. Faktiskt
          mer än många gäster. Därför ser jag det som en del av mitt uppdrag
          att, utöver fina bilder, bidra med en positiv närvaro. Den här dagen
          är jag inte bara en fotograf, jag är er vän som finns där för er och
          jag hoppas kunna tillföra både glädje och trygghet. Med det sagt,
          handlar såklart inte den här dagen om mig. Inte alls. Den handlar om
          er och er kärlek till varandra.
        </p>
        <button className="boka-btn" onClick={navigate}>
          BOKA HÄR
        </button>
      </div>
      <div className="images-container">
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Brollop/brollopsfoto2.jpg")}
            alt="lifestylefoto kalmar"
            className="image-half"
          ></img>
          <img
            src={require("../images/Hemsida/Brollop/brollopsfoto3.jpg")}
            alt="lifestylefoto kalmar"
            className="image-half"
          ></img>
        </div>
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Brollop/brollopsfoto5.jpg")}
            alt="lifestylefoto kalmar"
            className="image-half"
          ></img>
          <img
            src={require("../images/Hemsida/Brollop/brollopsfoto4.jpg")}
            alt="lifestylefoto kalmar"
            className="image-half"
          ></img>
        </div>
      </div>
      <div className="text-container">
        <p className="text-item">
          Jag vill fånga det genuina, lekfulla och känslosamma. För att få fram
          det kommer jag ge er små uppdrag under porträttfotograferingen som
          kommer hjälpa er att glömma kameran och i stället fokusera på
          varandra. Det är så äkta leenden och känslor uppstår, vilket bidrar
          till magiska bilder som fångar er, precis så som ni är. Under vigsel
          och mingel kommer jag inte styra utan fånga det som faktiskt händer
          och sker. Både för att ni ska minnas ögonblicken ni upplever.
          Kyrkdörrarna som öppnas, sången som värmer hjärtat och kyssen framför
          familjerna i bänkraderna. Men också för att ni i efterhand ska få se
          det ni kanske missar. Ett barn som gäspar bakom en psalmbok, en farmor
          som fäller en tår och en dattenlek som påbörjas i väntan på att ni ska
          komma ut och gratuleras av era gäster. Alla småglimtar från dagen
          bidrar till en vacker helhet.
        </p>
      </div>
      <div className="images-container">
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Brollop/brollopsfoto9.jpg")}
            alt="lifestylefoto kalmar"
            className="image-half"
          ></img>
          <img
            src={require("../images/Hemsida/Brollop/brollopsfoto10.jpg")}
            alt="lifestylefoto kalmar"
            className="image-half"
          ></img>
        </div>
      </div>
      <div className="text-container">
        <p className="text-header">EFTER FOTOGRAFERINGEN</p>
        <p className="text-item">
          Dagen efter bröllopet kommer ni få ett litet smakprov på ca 10 bilder.
          Ni kanske vill uppdatera era sociala medier eller bara njuta av en
          liten återblick från gårdagen. Inom sex veckor kommer resten av
          bilderna levereras digitalt via ett privat webbgalleri.
        </p>
      </div>
      <div className="images-container">
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Brollop/brollopsfoto6.jpg")}
            alt="lifestylefoto kalmar"
            className="image-half"
          ></img>
          <img
            src={require("../images/Hemsida/Brollop/brollopsfoto7.jpg")}
            alt="lifestylefoto kalmar"
            className="image-half"
          ></img>
        </div>
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Brollop/brollopsfoto8.jpg")}
            alt="lifestylefoto kalmar"
            className="image-full"
          ></img>
        </div>
      </div>
      <div className="text-container">
        <p className="text-header">PRIS</p>
        <div className="image-package-content wedding-price-container">
          <div className="image-package">
            <div className="package-price">
              <a style={{ fontSize: "26px", fontWeight: "bold" }}>11000kr</a>
              <a style={{ fontSize: "18px" }}>I priset ingår:</a>
            </div>
            <ul className="price-list-wedding">
              <li className="price-list-wedding-item">
                Planeringsmöte via telefon
              </li>
              <li className="price-list-wedding-item">Porträttfotografering</li>
              <li className="price-list-wedding-item">Vigsel</li>
              <li className="price-list-wedding-item">Gruppbilder</li>
              <li className="price-list-wedding-item">Utspring</li>
              <li className="price-list-wedding-item">Mingel</li>
            </ul>
          </div>
        </div>
        <button className="boka-btn" onClick={navigate}>
          BOKA HÄR
        </button>
      </div>
    </div>
  );
};

export default Weddingpage;
