import React from "react";
import "./Contactpage.css";
import "./Layout.css";
import { BsInstagram } from "react-icons/bs";
import { FiMail } from "react-icons/fi";

const Contactpage = () => {
  return (
    <div className="page-container">
      <img
        className="start-image"
        src={require("../images/Hemsida/bröllopsbild.JPG")}
        alt="bröllopsbild"
      ></img>
      <div className="start-image-text start-image-text-contact">
        <p className="start-image-text-item start-image-header">BOKA MIG</p>
        <p className="start-image-text-item">
          Släng iväg ett mail eller ett DM på Instagram så tar vi det därifrån
        </p>
      </div>
      <div className="contact-box-container">
        <div className="contact-box">
          <p className="contact-text">
            <FiMail size={24} className="social-logo"></FiMail>
            <a>kontakt@amandamediafoto.se</a>
          </p>
          <p className="contact-text">
            <BsInstagram size={24} className="social-logo"></BsInstagram>
            <a
              className="insta-link"
              target="_blank"
              href="https://www.instagram.com/amandamediafoto/"
            >
              amandamediafoto
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contactpage;
