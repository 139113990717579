import React, { useLayoutEffect } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { CgMenu } from "react-icons/cg";
import { useState } from "react";

const Navbar = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 1068) {
        setModalIsOpen(false);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <>
      <div className="navbar">
        <div className="navbar-elements">
          <Link
            className="navbar-element"
            to="/"
            style={
              props.active === "hem"
                ? { borderBottom: "solid rgb(156, 111, 37) 3px" }
                : {}
            }
          >
            Hem
          </Link>
          <Link
            className="navbar-element"
            to="/familj"
            style={
              props.active === "familj"
                ? { borderBottom: "solid rgb(156, 111, 37) 3px" }
                : {}
            }
          >
            Familj/barn
          </Link>
          <Link
            className="navbar-element"
            to="/par"
            style={
              props.active === "par"
                ? { borderBottom: "solid rgb(156, 111, 37) 3px" }
                : {}
            }
          >
            Par
          </Link>
          <Link
            className="navbar-element"
            to="/gravid"
            style={
              props.active === "gravid"
                ? { borderBottom: "solid rgb(156, 111, 37) 3px" }
                : {}
            }
          >
            Gravid/nyfödd
          </Link>
          <Link
            className="navbar-element"
            to="/lifestyle"
            style={
              props.active === "lifestyle"
                ? { borderBottom: "solid rgb(156, 111, 37) 3px" }
                : {}
            }
          >
            Lifestyle
          </Link>
          <Link
            className="navbar-element"
            to="/brollop"
            style={
              props.active === "brollop"
                ? { borderBottom: "solid rgb(156, 111, 37) 3px" }
                : {}
            }
          >
            Bröllop
          </Link>
          <Link
            className="navbar-element navbar-last-element"
            to="/kontakt"
            style={
              props.active === "kontakt"
                ? { borderBottom: "solid rgb(156, 111, 37) 3px" }
                : {}
            }
          >
            Kontakt
          </Link>
          <CgMenu
            className="navbar-menu"
            size={30}
            onClick={toggleModal}
            style={
              modalIsOpen
                ? {
                    color: "rgb(156, 111, 37)",
                    border: "solid rgb(156, 111, 37) 1px",
                    position: "fixed",
                    top: "20px",
                    right: "0px",
                  }
                : {}
            }
          ></CgMenu>
        </div>
      </div>
      <Modal isOpen={modalIsOpen} className="modal" closeTimeoutMS={500}>
        <div className="mobile-navbar-menu">
          <Link
            className="mobile-navbar-menu-item"
            to="/"
            style={
              props.active === "hem"
                ? { borderBottom: "solid rgb(156, 111, 37) 3px" }
                : {}
            }
            onClick={toggleModal}
          >
            Hem
          </Link>
          <Link
            className="mobile-navbar-menu-item"
            to="/familj"
            style={
              props.active === "familj"
                ? { borderBottom: "solid rgb(156, 111, 37) 3px" }
                : {}
            }
            onClick={toggleModal}
          >
            Familj/barn
          </Link>
          <Link
            className="mobile-navbar-menu-item"
            to="/par"
            style={
              props.active === "par"
                ? { borderBottom: "solid rgb(156, 111, 37) 3px" }
                : {}
            }
            onClick={toggleModal}
          >
            Par
          </Link>
          <Link
            className="mobile-navbar-menu-item"
            to="/gravid"
            style={
              props.active === "gravid"
                ? { borderBottom: "solid rgb(156, 111, 37) 3px" }
                : {}
            }
            onClick={toggleModal}
          >
            Gravid/nyfödd
          </Link>
          <Link
            className="mobile-navbar-menu-item"
            to="/lifestyle"
            style={
              props.active === "lifestyle"
                ? { borderBottom: "solid rgb(156, 111, 37) 3px" }
                : {}
            }
            onClick={toggleModal}
          >
            Lifestyle
          </Link>
          <Link
            className="mobile-navbar-menu-item"
            to="/brollop"
            style={
              props.active === "brollop"
                ? { borderBottom: "solid rgb(156, 111, 37) 3px" }
                : {}
            }
            onClick={toggleModal}
          >
            Bröllop
          </Link>
          <Link
            className="mobile-navbar-menu-item"
            to="/kontakt"
            style={
              props.active === "kontakt"
                ? { borderBottom: "solid rgb(156, 111, 37) 3px" }
                : {}
            }
            onClick={toggleModal}
          >
            Kontakt
          </Link>
        </div>
      </Modal>
    </>
  );
};

export default Navbar;
