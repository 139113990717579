import React from "react";
import "./Pregnantpage.css";
import "./Layout.css";
import { useNavigate } from "react-router-dom";

const Pregnantpage = () => {
  const nav = useNavigate();

  const navigate = () => {
    nav("/kontakt");
  };

  return (
    <div className="page-container">
      <img
        className="start-image"
        src={require("../images/Hemsida/Gravid/gravidfoto.jpg")}
        alt="gravidbild"
      ></img>
      <div className="start-image-text">
        <p className="start-image-text-item start-image-header">
          GRAVID-/ NYFÖDDFOTOGRAFERING
        </p>
        <p className="start-image-text-item">
          En äkta, naturlig och rolig stund som förevigar en tid som aldrig
          kommer tillbaka
        </p>
      </div>
      <div className="text-container">
        <p className="text-header">GRAVIDFOTOGRAFERING</p>
        <p className="text-item">
          Ett litet hjärta som dunkar under ditt. En växande mage som skvallrar
          om det som komma skall. Vissa stunder känns allting vackert. Andra
          stunder kan det kännas tungt och jobbigt. En graviditet är aldrig den
          andra lik, men de resulterar i något helt fantastiskt. En tid som
          aldrig kommer tillbaka, men som många vill föreviga för att minnas.
          Gjorde jag själv en gravidfotografering? Svar: Nej. Ångrar jag mig?
          Svar: JA!
        </p>
        <p className="text-item">
          En gravidfotografering är en lugn fotografering där du kommer få
          tänka, känna och glädjas åt det som komma skall. Jag guidar dig genom
          fotograferingen för att du ska känna dig så bekväm som möjligt. Du kan
          även välja att göra en del av fotograferingen tillsammans med din
          partner. För även om barnet ligger i din mage är ni förmodligen två
          personer som ska bli föräldrar. Längtan är delad och det kan vara
          väldigt fint att föreviga hela den blivande lilla familjen. Syskon är
          också välkomna.
        </p>
        <p className="text-item" style={{ fontWeight: "bold" }}>
          Om du bokar en gravidfotografering bjuder jag på
          fotograferingsavgiften vid en nyföddfotografering när bebisen är här.
        </p>
        <button className="boka-btn" onClick={navigate}>
          BOKA HÄR
        </button>
      </div>
      <div className="images-container">
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Gravid/gravidfoto1.jpg")}
            alt="gravidfoto kalmar"
            className="image-half"
          ></img>
          <img
            src={require("../images/Hemsida/Gravid/gravidfoto2.jpg")}
            alt="gravidfoto kalmar"
            className="image-half"
          ></img>
        </div>
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Gravid/gravidfoto3.jpg")}
            alt="gravidfoto kalmar"
            className="image-full"
          ></img>
        </div>
      </div>
      <div className="text-container">
        <p className="text-header">NYFÖDDFOTOGRAFERING</p>
        <p className="text-item">
          Så plötsligt en dag sitter du där med livet i din famn. Alla som har
          barn vet hur snabbt den första tiden går. Det känns som att de små
          älsklingarna växer varje gång man blinkar. Vid en nyföddfotografering
          släpper ni in mig i den omtalade bebisbubblan. Jag kommer alltså hem
          till er och fångar tiden som så snabbt är förbi. Här fokuserar vi på
          mys, gos och närhet. Självklart går det precis lika bra att göra den
          här fotograferingen när bebisen är några månader gammal. Varje månad
          har sin charm och ni bestämmer när ni är redo!
        </p>
        <button className="boka-btn" onClick={navigate}>
          BOKA HÄR
        </button>
      </div>
      <div className="images-container">
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Gravid/gravidfoto4.jpg")}
            alt="gravidfoto kalmar"
            className="image-half"
          ></img>
          <img
            src={require("../images/Hemsida/Gravid/gravidfoto5.jpg")}
            alt="gravidfoto kalmar"
            className="image-half"
          ></img>
        </div>
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Gravid/gravidfoto6.jpg")}
            alt="gravidfoto kalmar"
            className="image-full"
          ></img>
        </div>
      </div>
      <div className="text-container">
        <p className="text-header">BOKAT? YES! VAD HÄNDER NU?</p>
        <p className="text-item">
          Jag skickar över en klädguide med lite riktlinjer kring vad kameran
          föredrar och inte. Ni får även ett dokument med villkor som ni ska
          godkänna skriftligt där det till exempel står vad som händer om vi
          blir sjuka, om det regnar, info kring bildanvändning och vilka
          betalningsvillkor som gäller. Jag mejlar också över fakturan på
          fotograferingsavgiften. Den betalas alltså före fotograferingen och
          pengarna får man inte tillbaka vid avbokning. Sedan är det bara att
          vänta och längta efter dagen då vi tillsammans skapar bilder för
          livet!
        </p>
        <button className="boka-btn" onClick={navigate}>
          BOKA HÄR
        </button>
      </div>
      <div className="images-container">
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Gravid/gravidfoto7.jpg")}
            alt="gravidfoto kalmar"
            className="image-half"
          ></img>
          <img
            src={require("../images/Hemsida/Gravid/gravidfoto8.jpg")}
            alt="gravidfoto kalmar"
            className="image-half"
          ></img>
        </div>
      </div>
      <div className="text-container">
        <p className="text-header">EFTER FOTOGRAFERINGEN</p>
        <p className="text-item">
          Inom två veckor får ni ett webbgalleri skickat till er med alla
          bilder. Titta på dem i lugn och ro och välj ut hur många och vilka
          bilder ni vill köpa utifrån mina tre paket. När ni gjort ert val
          skickar jag fakturan på den aktuella summan och ni får ett nytt
          webbgalleri med de bilderna ni valt. Dessa är då högupplösta och
          nedladdningsbara.
        </p>
      </div>
      <div className="images-container">
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Gravid/gravidfoto9.jpg")}
            alt="gravidfoto kalmar"
            className="image-half"
          ></img>
        </div>
      </div>
      <div className="text-container">
        <p className="text-header">PRIS</p>
        <p className="text-item">
          En bild är en av få saker som faktiskt ökar i värde med tiden. Därav
          är en fotografering bland den bästa investeringen du kan göra.
          Självklart är det fantastiskt att få öppna bildgalleriet för första
          gången, men det kommer betyda ännu mer i framtiden! Nuet glider ifrån
          oss så snabbt, men genom en fotografering bevaras det för alltid.
          <br></br>
          <br></br>När bokningen är gjord betalar ni en fotograferingsavgift.
          Efter fotograferingen har ni tre bildpaket att välja mellan som köpes
          till separat. Se nedan.
        </p>
        <div className="price-container">
          <p className="price-header" style={{ color: "white" }}>
            FOTOGRAFERINGSAVGIFT
          </p>
          <div className="price-row">
            <p className="price-text">
              <a style={{ fontSize: "26px" }}>1000kr</a>
            </p>
            <p className="price-text" style={{ marginBottom: "26px" }}>
              Ingår ca en timmes fotografering
            </p>
          </div>
        </div>
        <div className="image-package-container">
          <div className="image-package-content">
            <p className="image-package-header">Lilla paketet</p>
            <div className="image-package">
              <div className="package-price">
                <a style={{ fontSize: "26px", fontWeight: "bold" }}>1500kr</a>
                <a>(exklusive fotograferingsavgift)</a>
              </div>
              <ul className="price-list">
                <li className="price-list-item">5 Bilder</li>
                <li className="price-list-item">Redigerade & högupplösta</li>
                <li className="price-list-item">
                  Levereras via ett webbgalleri
                </li>
              </ul>
            </div>
          </div>
          <div className="image-package-content">
            <p className="image-package-header">Mellanpaketet</p>
            <div className="image-package">
              <div className="package-price">
                <a style={{ fontSize: "26px", fontWeight: "bold" }}>2500kr</a>
                <a>(exklusive fotograferingsavgift)</a>
              </div>
              <ul className="price-list">
                <li>20 Bilder</li>
                <li>Redigerade & högupplösta</li>
                <li>Levereras via ett webbgalleri</li>
              </ul>
            </div>
          </div>
          <div className="image-package-content">
            <p className="image-package-header">Stora paketet</p>
            <div className="image-package">
              <div className="package-price">
                <a style={{ fontSize: "26px", fontWeight: "bold" }}>3500kr</a>
                <a>(exklusive fotograferingsavgift)</a>
              </div>
              <ul className="price-list">
                <li>Alla Bilder</li>
                <li>Redigerade & högupplösta</li>
                <li>Levereras via ett webbgalleri</li>
              </ul>
            </div>
          </div>
        </div>
        <button className="boka-btn" onClick={navigate}>
          BOKA HÄR
        </button>
      </div>
    </div>
  );
};

export default Pregnantpage;
