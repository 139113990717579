import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Homepage from "./components/Homepage";
import Familypage from "./components/Familypage";
import Couplepage from "./components/Couplepage";
import Pregnantpage from "./components/Pregnantpage";
import Contactpage from "./components/Contactpage";
import Lifestylepage from "./components/Lifestylepage";
import Weddingpage from "./components/Weddingpage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar active="hem" />
                <Homepage />
              </>
            }
          />
          <Route
            path="/familj"
            element={
              <>
                <Navbar active="familj" />
                <Familypage />
              </>
            }
          />
          <Route
            path="/par"
            element={
              <>
                <Navbar active="par" />
                <Couplepage />
              </>
            }
          />
          <Route
            path="/gravid"
            element={
              <>
                <Navbar active="gravid" />
                <Pregnantpage />
              </>
            }
          />
          <Route
            path="/lifestyle"
            element={
              <>
                <Navbar active="lifestyle" />
                <Lifestylepage />
              </>
            }
          />
          <Route
            path="/brollop"
            element={
              <>
                <Navbar active="brollop" />
                <Weddingpage />
              </>
            }
          />
          <Route
            path="/kontakt"
            element={
              <>
                <Navbar active="kontakt" />
                <Contactpage />
              </>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
