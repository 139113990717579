import React from "react";
import { BsCamera } from "react-icons/bs";
import "./Layout.css";
import { useNavigate } from "react-router-dom";

const Familypage = () => {
  const nav = useNavigate();

  const navigate = () => {
    nav("/kontakt");
  };

  return (
    <div className="page-container">
      <img
        className="start-image"
        src={require("../images/Hemsida/Familj/familjefoto.jpg")}
        alt="familjebild"
      ></img>
      <div className="start-image-text">
        <p className="start-image-text-item start-image-header">
          FAMILJEFOTOGRAFERING
        </p>
        <p className="start-image-text-item">
          En lekfull, mysig & rolig stund som ni alltid kommer minnas
        </p>
      </div>
      <div className="text-container">
        <p className="text-item">
          Jag har mängder med bilder från min barndom där jag och min bror
          sitter på fina stenar eller står och håller om varandra, tittar in i
          kameran och ler ansträngt med hopbitna tänder. Det är så långt ifrån
          en familjefotografering med mig man kan komma. Istället för att be
          barnen att le kommer jag ta till flera knep för att få dem att göra
          det. Äkta leenden och skratt lockas fram genom lek, bus och gos. Det
          är tre ingredienser det finns gott om under en familjefotografering.
          Det är de äkta och naturliga ögonblicken jag vill fånga för det är i
          dem den genuina kärleken som finns inom en familj lyser som allra
          starkast.
        </p>
        <p className="text-item">
          Fotograferingen sker utomhus på en plats vi väljer tillsammans.
        </p>
        <button className="boka-btn" onClick={navigate}>
          BOKA HÄR
        </button>
      </div>
      <div className="images-container">
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Familj/familjefoto1.jpg")}
            alt="familjefoto kalmar"
            className="image-half"
          ></img>
          <img
            src={require("../images/Hemsida/Familj/familjefoto2.jpg")}
            alt="familjefoto kalmar"
            className="image-half"
          ></img>
        </div>
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Familj/familjefoto12.jpg")}
            alt="familjefoto kalmar"
            className="image-full"
          ></img>
        </div>
      </div>
      <div className="text-container">
        <p className="text-header">SÅ HÄR GÅR DET TILL</p>
        <p className="text-item">
          Jag kommer ge små uppdrag under fotograferingens gång som jag alltid
          anpassar efter barnens humör, personlighet och dagsform. Är benen
          fulla med spring kanske de får svingas som flygplan mot mig, kastas
          upp i luften, eller springa fram och kittla sina föräldrar. Om barnen
          är lite mer tillbakadragna känns det kanske tryggast i någons famn och
          jag ger i stället uppdrag som att lyssna på mammas hjärta eller måla
          något med näsan på pappas kind. Jag har helt enkelt en godispåse full
          med idéer och plockar fram det som passar och rättar mig efter barnen.
          Antingen blir det glada och lekfulla bilder, eller mysiga och intima.
          Förhoppningsvis får vi till båda delarna!
          <br></br>
          <br></br>Under en familjefotografering fotar vi alla konstellationer
          ni önskar. Hela familjen, syskon, enskilda, barn och förälder…ja, I am
          all yours and up for everything!
        </p>
      </div>
      <div className="images-container">
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Familj/familjefoto4.jpg")}
            alt="familjefoto kalmar"
            className="image-half"
          ></img>
          <img
            src={require("../images/Hemsida/Familj/familjefoto5.jpg")}
            alt="familjefoto kalmar"
            className="image-half"
          ></img>
        </div>
      </div>
      <div className="text-container">
        <p className="text-header">BARN-/ SYSKONFOTOGRAFERING</p>
        <p className="text-item">
          Vid en barn-/syskonfotografering blir det också lek och bus, men
          främst fokus på porträtt. Vi använder rekvisita i form av låda eller
          en ministol i rotting. En barnfotografering går generellt snabbare än
          en familjefotografering. Därför blir själva fotograferingsavgiften
          lite mindre. Dock gäller samma bildpaket.
        </p>
        <button className="boka-btn" onClick={navigate}>
          BOKA HÄR
        </button>
      </div>
      <div className="images-container">
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Familj/familjefoto6.jpg")}
            alt="familjefoto kalmar"
            className="image-half"
          ></img>
          <img
            src={require("../images/Hemsida/Familj/familjefoto7.jpg")}
            alt="familjefoto kalmar"
            className="image-half"
          ></img>
        </div>
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Familj/familjefoto8.jpg")}
            alt="familjefoto kalmar"
            className="image-full"
          ></img>
        </div>
      </div>
      <div className="text-container">
        <p className="text-header">BOKAT? YES! VAD HÄNDER NU?</p>
        <p className="text-item">
          Jag skickar över en klädguide med lite riktlinjer kring vad kameran
          föredrar och inte. Ni får även ett dokument med villkor som ni ska
          godkänna skriftligt där det till exempel står vad som händer om vi
          blir sjuka, om det regnar, info kring bildanvändning och vilka
          betalningsvillkor som gäller. Jag mejlar också över fakturan på
          fotograferingsavgiften. Den betalas alltså före fotograferingen och
          pengarna får man inte tillbaka vid avbokning. Sedan är det bara att
          vänta och längta efter dagen då vi tillsammans skapar bilder för
          livet!
        </p>
      </div>
      <div className="images-container">
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Familj/familjefoto9.jpg")}
            alt="familjefoto kalmar"
            className="image-half"
          ></img>
        </div>
      </div>
      <div className="text-container">
        <p className="text-header">EFTER FOTOGRAFERINGEN</p>
        <p className="text-item">
          Inom två veckor får ni ett webbgalleri skickat till er med alla
          bilder. Titta på dem i lugn och ro och välj ut hur många och vilka
          bilder ni vill köpa utifrån mina tre paket. När ni gjort ert val
          skickar jag fakturan på den aktuella summan och ni får ett nytt
          webbgalleri med de bilderna ni valt. Dessa är då högupplösta och
          nedladdningsbara.
        </p>
      </div>
      <div className="images-container">
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Familj/familjefoto10.jpg")}
            alt="familjefoto kalmar"
            className="image-half"
          ></img>
          <img
            src={require("../images/Hemsida/Familj/familjefoto11.jpg")}
            alt="familjefoto kalmar"
            className="image-half"
          ></img>
        </div>
      </div>
      <div className="text-container">
        <p className="text-header">PRIS</p>
        <p className="text-item">
          En bild är en av få saker som faktiskt ökar i värde med tiden. Därav
          är en fotografering bland den bästa investeringen du kan göra.
          Självklart är det fantastiskt att få öppna bildgalleriet för första
          gången, men det kommer betyda ännu mer i framtiden! Nuet glider ifrån
          oss så snabbt, men genom en fotografering bevaras det för alltid.
          <br></br>
          <br></br>När bokningen är gjord betalar ni en fotograferingsavgift.
          Efter fotograferingen har ni tre bildpaket att välja mellan som köpes
          till separat. Se nedan.
        </p>
        <div className="price-container">
          <p className="price-header" style={{ color: "white" }}>
            FOTOGRAFERINGSAVGIFT
          </p>
          <div className="price-row">
            <p className="price-text">
              <a style={{ fontSize: "26px" }}>1000kr</a>
              <a> (familj)</a>
            </p>
            <p className="price-text">
              <a style={{ fontSize: "26px" }}>500kr</a>
              <a> (barn/syskon)</a>
            </p>
            <p className="price-text" style={{ marginBottom: "26px" }}>
              Ingår ca en timmes respektive 40 min fotografering
            </p>
          </div>
        </div>
        <div className="image-package-container">
          <div className="image-package-content">
            <p className="image-package-header">Lilla paketet</p>
            <div className="image-package">
              <div className="package-price">
                <a style={{ fontSize: "26px", fontWeight: "bold" }}>1500kr</a>
                <a>(exklusive fotograferingsavgift)</a>
              </div>
              <ul className="price-list">
                <li className="price-list-item">5 Bilder</li>
                <li className="price-list-item">Redigerade & högupplösta</li>
                <li className="price-list-item">
                  Levereras via ett webbgalleri
                </li>
              </ul>
            </div>
          </div>
          <div className="image-package-content">
            <p className="image-package-header">Mellanpaketet</p>
            <div className="image-package">
              <div className="package-price">
                <a style={{ fontSize: "26px", fontWeight: "bold" }}>2500kr</a>
                <a>(exklusive fotograferingsavgift)</a>
              </div>
              <ul className="price-list">
                <li>20 Bilder</li>
                <li>Redigerade & högupplösta</li>
                <li>Levereras via ett webbgalleri</li>
              </ul>
            </div>
          </div>
          <div className="image-package-content">
            <p className="image-package-header">Stora paketet</p>
            <div className="image-package">
              <div className="package-price">
                <a style={{ fontSize: "26px", fontWeight: "bold" }}>3500kr</a>
                <a>(exklusive fotograferingsavgift)</a>
              </div>
              <ul className="price-list">
                <li>Alla Bilder</li>
                <li>Redigerade & högupplösta</li>
                <li>Levereras via ett webbgalleri</li>
              </ul>
            </div>
          </div>
        </div>
        <button className="boka-btn" onClick={navigate}>
          BOKA HÄR
        </button>
      </div>
    </div>
  );
};

export default Familypage;
