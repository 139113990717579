import React, { useLayoutEffect, useState } from "react";
import "./Homepage.css";
import "./Layout.css";
import { BsInstagram } from "react-icons/bs";
import CarouselComponent from "./CarouselComponent";

const Homepage = () => {
  const [screenWidth, setScreenWidth] = useState([window.innerWidth]);
  useLayoutEffect(() => {
    function updateSize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div className="page-container">
      <img
        className="start-image"
        src={require("../images/Hemsida/bröllopsfoto.jpg")}
        alt="bröllopsbild"
      ></img>
      <div className="start-image-text">
        <p className="start-image-text-item start-image-header">
          Amanda Media Foto
        </p>
        <p className="start-image-text-item">
          Fotograf i Kalmar, Vimmerby & Hultsfred
        </p>
        <div
          className="homepage-instagram-container start-image-text-item"
          style={{ marginTop: "-10px" }}
        >
          <BsInstagram className="start-image-text-item insta-icon" />
          <a>amandamediafoto</a>
        </div>
      </div>
      <div className="carousel-container">
        <div className="text-container-carousel">
          <h1 className="text-header">SÅ HÄR SÄGER TIDIGARE KUNDER</h1>
          <CarouselComponent />
        </div>
      </div>
      {screenWidth > 1080 ? (
        <div className="this-is-me-container">
          <img
            src={require("../images/Hemsida/amanda_karlsson.JPG")}
            alt="foto Amanda"
            className="this-is-me-image"
          ></img>
          <div className="text-container">
            <h1 className="text-header">DET HÄR ÄR JAG</h1>
            <p className="text-item uncenter-last">
              Den här sidan tillhör en 29-årig tvåbarnsmamma som är bosatt i
              Kalmar, men har rötterna i Vimmerby. När jag inte fotograferar
              eller leker kurragömma sitter jag med näsan i
              förundersökningsprotokoll. Min huvudyrkestitel är nämligen
              podcastkreatör och jag är en av skaparna bakom Mordpodden. Jag
              älskar mitt jobb, men i Mordpodden-formatet är jag relativt låst
              och saknar kollegor, vilket får det att klia i mina kreativa
              fingrar och sociala ådror. Jag är i själva verket en idéspruta som
              älskar att träffa nya människor. De sidorna får jag utlopp för när
              det kommer till mitt fotograferande. Kameran är som mitt tredje
              barn. Den har under många år fått följa med på familjens alla
              galna och mysiga upptåg. Men att även få den underbara möjligheten
              att föreviga andra människors vackra stunder och kärlek till
              varandra är helt fantastiskt.
            </p>
            <p className="text-item uncenter-last">
              Jag har läst flera fotokurser både på gymnasiet och universitetet,
              men i min värld kan man inte plugga sig till att bli en bra
              fotograf. Det handlar om så mycket mer än att förstå sig på
              kamerainställningar. Det vackra händer inte i kameran utan
              framför. För att skapa naturliga ögonblick och äkta leenden och
              känslor är det mitt jobb som fotograf att hjälpa människor att
              känna sig bekväma och njuta av stunden. Den utmaningen tycker jag
              är det absolut roligaste med det här yrket. Det är så viktigt att
              jag och ni får en rolig stund tillsammans. Det är då det blir som
              allra bäst.
            </p>
          </div>
        </div>
      ) : (
        <div className="this-is-me-container">
          <div className="text-container">
            <h1 className="text-header">DET HÄR ÄR JAG</h1>
            <p className="text-item uncenter-last">
              Den här sidan tillhör en 29-årig tvåbarnsmamma som är bosatt i
              Kalmar, men har rötterna i Vimmerby. När jag inte fotograferar
              eller leker kurragömma sitter jag med näsan i
              förundersökningsprotokoll. Min huvudyrkestitel är nämligen
              podcastkreatör och jag är en av skaparna bakom Mordpodden. Jag
              älskar mitt jobb, men i Mordpodden-formatet är jag relativt låst
              och saknar kollegor, vilket får det att klia i mina kreativa
              fingrar och sociala ådror. Jag är i själva verket en idéspruta som
              älskar att träffa nya människor. De sidorna får jag utlopp för när
              det kommer till mitt fotograferande. Kameran är som mitt tredje
              barn. Den har under många år fått följa med på familjens alla
              galna och mysiga upptåg. Men att även få den underbara möjligheten
              att föreviga andra människors vackra stunder och kärlek till
              varandra är helt fantastiskt.
            </p>
            <p className="text-item uncenter-last">
              Jag har läst flera fotokurser både på gymnasiet och universitetet,
              men i min värld kan man inte plugga sig till att bli en bra
              fotograf. Det handlar om så mycket mer än att förstå sig på
              kamerainställningar. Det vackra händer inte i kameran utan
              framför. För att skapa naturliga ögonblick och äkta leenden och
              känslor är det mitt jobb som fotograf att hjälpa människor att
              känna sig bekväma och njuta av stunden. Den utmaningen tycker jag
              är det absolut roligaste med det här yrket. Det är så viktigt att
              jag och ni får en rolig stund tillsammans. Det är då det blir som
              allra bäst.
            </p>
          </div>
          <img
            src={require("../images/Hemsida/amanda_karlsson.JPG")}
            alt="foto Amanda"
            className="this-is-me-image"
          ></img>
        </div>
      )}
    </div>
  );
};

export default Homepage;
