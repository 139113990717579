import React from "react";
import "./Layout.css";
import { useNavigate } from "react-router-dom";

const Lifestylepage = () => {
  const nav = useNavigate();

  const navigate = () => {
    nav("/kontakt");
  };

  return (
    <div className="page-container">
      <img
        className="start-image"
        src={require("../images/Hemsida/Lifestyle/lifestylefoto.jpg")}
        alt="lifestylebild"
      ></img>
      <div className="start-image-text">
        <p className="start-image-text-item start-image-header">
          Lifestylefotografering
        </p>
        <p className="start-image-text-item">
          Äkta bilder som speglar er tid här och nu
        </p>
      </div>
      <div className="text-container">
        <p className="text-item">
          En lifestylefotografering…hmmm…vad är det? Det innebär att jag kommer
          hem till er och fotograferar er i er rätta miljö precis som ni är.
          Äkta bilder som speglar er tid här och nu. Först tar jag lite bilder
          som jag styr upp. Sedan är det upp till er! Välj en aktivitet där jag
          blir en fluga på väggen. Det kan t.ex. vara bakning, matlagning eller
          varför inte gräva fram vattenfärg och penslar. Ni har en rolig stund
          tillsammans och jag ser till att föreviga den åt er.
        </p>
        <button className="boka-btn" onClick={navigate}>
          BOKA HÄR
        </button>
      </div>
      <div className="images-container">
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Lifestyle/lifestylefoto1.jpg")}
            alt="lifestylefoto kalmar"
            className="image-half"
          ></img>
          <img
            src={require("../images/Hemsida/Lifestyle/lifestylefoto2.jpg")}
            alt="lifestylefoto kalmar"
            className="image-half"
          ></img>
        </div>
      </div>
      <div className="text-container">
        <p className="text-header">BOKAT? YES! VAD HÄNDER NU?</p>
        <p className="text-item">
          Jag skickar över en klädguide med lite riktlinjer kring vad kameran
          föredrar och inte. Ni får även ett dokument med villkor som ni ska
          godkänna skriftligt där det till exempel står vad som händer om vi
          blir sjuka, om det regnar, info kring bildanvändning och vilka
          betalningsvillkor som gäller. Jag mejlar också över fakturan på
          fotograferingsavgiften. Den betalas alltså före fotograferingen och
          pengarna får man inte tillbaka vid avbokning. Sedan är det bara att
          vänta och längta efter dagen då vi tillsammans skapar bilder för
          livet!
        </p>
        <button className="boka-btn" onClick={navigate}>
          BOKA HÄR
        </button>
      </div>
      <div className="images-container">
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Lifestyle/lifestylefoto3.jpg")}
            alt="lifestylefoto kalmar"
            className="image-half"
          ></img>
        </div>
      </div>
      <div className="text-container">
        <p className="text-header">EFTER FOTOGRAFERINGEN</p>
        <p className="text-item">
          Inom två veckor får ni ett webbgalleri skickat till er med alla
          bilder. Titta på dem i lugn och ro och välj ut hur många och vilka
          bilder ni vill köpa utifrån mina tre paket. När ni gjort ert val
          skickar jag fakturan på den aktuella summan och ni får ett nytt
          webbgalleri med de bilderna ni valt. Dessa är då högupplösta och
          nedladdningsbara.
        </p>
      </div>
      <div className="images-container">
        <div className="image-row">
          <img
            src={require("../images/Hemsida/Lifestyle/lifestylefoto4.jpg")}
            alt="lifestylefoto kalmar"
            className="image-half"
          ></img>
          <img
            src={require("../images/Hemsida/Lifestyle/lifestylefoto5.jpg")}
            alt="lifestylefoto kalmar"
            className="image-half"
          ></img>
        </div>
      </div>
      <div className="text-container">
        <p className="text-header">PRIS</p>
        <p className="text-item">
          En bild är en av få saker som faktiskt ökar i värde med tiden. Därav
          är en fotografering bland den bästa investeringen du kan göra.
          Självklart är det fantastiskt att få öppna bildgalleriet för första
          gången, men det kommer betyda ännu mer i framtiden! Nuet glider ifrån
          oss så snabbt, men genom en fotografering bevaras det för alltid.
          <br></br>
          <br></br>När bokningen är gjord betalar ni en fotograferingsavgift.
          Efter fotograferingen har ni tre bildpaket att välja mellan som köpes
          till separat. Se nedan.
        </p>
        <div className="price-container">
          <p className="price-header" style={{ color: "white" }}>
            FOTOGRAFERINGSAVGIFT
          </p>
          <div className="price-row">
            <p className="price-text">
              <a style={{ fontSize: "26px" }}>1000kr</a>
            </p>
            <p className="price-text" style={{ marginBottom: "26px" }}>
              Ingår ca en timmes fotografering
            </p>
          </div>
        </div>
        <div className="image-package-container">
          <div className="image-package-content">
            <p className="image-package-header">Lilla paketet</p>
            <div className="image-package">
              <div className="package-price">
                <a style={{ fontSize: "26px", fontWeight: "bold" }}>1500kr</a>
                <a>(exklusive fotograferingsavgift)</a>
              </div>
              <ul className="price-list">
                <li className="price-list-item">5 Bilder</li>
                <li className="price-list-item">Redigerade & högupplösta</li>
                <li className="price-list-item">
                  Levereras via ett webbgalleri
                </li>
              </ul>
            </div>
          </div>
          <div className="image-package-content">
            <p className="image-package-header">Mellanpaketet</p>
            <div className="image-package">
              <div className="package-price">
                <a style={{ fontSize: "26px", fontWeight: "bold" }}>2500kr</a>
                <a>(exklusive fotograferingsavgift)</a>
              </div>
              <ul className="price-list">
                <li>20 Bilder</li>
                <li>Redigerade & högupplösta</li>
                <li>Levereras via ett webbgalleri</li>
              </ul>
            </div>
          </div>
          <div className="image-package-content">
            <p className="image-package-header">Stora paketet</p>
            <div className="image-package">
              <div className="package-price">
                <a style={{ fontSize: "26px", fontWeight: "bold" }}>3500kr</a>
                <a>(exklusive fotograferingsavgift)</a>
              </div>
              <ul className="price-list">
                <li>Alla Bilder</li>
                <li>Redigerade & högupplösta</li>
                <li>Levereras via ett webbgalleri</li>
              </ul>
            </div>
          </div>
        </div>
        <button className="boka-btn" onClick={navigate}>
          BOKA HÄR
        </button>
      </div>
    </div>
  );
};

export default Lifestylepage;
